;
// Drag & drop on CMS page
$(function () {
    var root = $('#cms-root');

    if (!root.length) return;

    var
        left = $('#cms-palette'),
        right = $('#cms-content')
        ;

    (function () {
        dragula(left.toArray(), {
            isContainer: function (el) {
                return el === left[0] || $(el).is('[data-accepts]');
            },
            copy: function (el, source) {
                return source === left[0];
            },
            accepts: function (el, target, source, sibling) {
                return target === left[0] || ($(target).attr('data-accepts') || '').indexOf($(el).attr('data-is')) !== -1;
            },
            moves: function (el, container, handle) {
                var h = $(handle);

                return h.hasClass('card-header') || (!h.hasClass('card-tools') && !h.closest('.card-tools').length && h.closest('.card-header').length);
            },  
            getDirection: function (target) {
                return $(target).attr('data-direction') || 'vertical';
            }
        })
            .on('drop', function (el, target, source, sibling) {
                if (target == left[0]) {
                    $(el).remove();
                    return;
                }

                if (($(target).attr('data-accepts') || '').indexOf($(el).attr('data-is')) !== -1) {
                    right.trigger('project.added', [el, target]);
                }
            })
            .on('over', function (el, container, source) {
                $(container).addClass('drag-over');
            })
            .on('out', function (el, container, source) {
                $(container).removeClass('drag-over');
            })
            ;

        root.on('click', '[data-card-widget=trash]', function () {
            $(this).closest('.card').remove();
        });
    })();
});

// CMS page json logic
$(function () {
    var root = $('#cms-root'),
        right = $('#cms-content')
        ;

    if (!root.length) return;

    var initForm = function (item) {
        var lang = 'en' || $('html').attr('lang');

        var htmlStyle = $('#editor-style').html();

        $('[data-toggle=html]', item).each(function (i) {
            var e = $(this),
                toolbar = e.attr('data-toolbar') || 'undo redo | bold italic link image numlist bullist | removeformat',
                plugins = e.attr('data-plugins') || 'paste lists link code image',
                contextmenu = e.attr('data-contextmenu') || 'link table',
                css = e.attr('data-editor-css'),
                uploadurl = e.attr('data-upload-url'),
                uuid = uuidv4()
                ;

            e.attr('id', uuid);

            tinymce.init({
                selector: '#' + uuid,
                branding: false,
                menubar: false,
                statusbar: e.attr('data-html-statusbar') == 'true',
                min_height: 20,
                language: lang || 'en',
                skin: i == 0 ? 'oxide' : false, // Only on first
                plugins: plugins,
                contextmenu: contextmenu,
                paste_block_drop: false,
                paste_retain_style_properties: 'color font-size',
                toolbar: [toolbar].join(' | '),
                table_sizing_mode: 'relative',
                content_css: css || false,
                content_style: htmlStyle,
                //body_class: '',

                // Photo upload
                automatic_uploads: true,
                file_picker_types: 'jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp',
                images_upload_base_path: false,
                images_upload_handler: function (blobInfo, success, failure, progress) {
                    var xhr, formData;

                    xhr = new XMLHttpRequest();
                    xhr.withCredentials = false;
                    xhr.open('POST', uploadurl);

                    xhr.upload.onprogress = function (e) {
                        progress(e.loaded / e.total * 100);
                    };

                    xhr.onload = function () {
                        var json;

                        if (xhr.status === 403) {
                            failure('HTTP Error: ' + xhr.status, { remove: true });
                            return;
                        }

                        if (xhr.status < 200 || xhr.status >= 300) {
                            failure('HTTP Error: ' + xhr.status);
                            return;
                        }

                        json = JSON.parse(xhr.responseText);

                        if (!json || typeof json.location != 'string') {
                            failure('Invalid JSON: ' + xhr.responseText);
                            return;
                        }

                        success(json.location);
                    };

                    xhr.onerror = function () {
                        failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
                    };

                    formData = new FormData();
                    formData.append('files', blobInfo.blob(), blobInfo.filename());

                    xhr.send(formData);
                },

                relative_urls: false,
                remove_script_host: false,
                convert_urls: true,

                setup: function (editor) {
                    editor.on('change', function () {
                        editor.save();
                    });
                },
            });
        });

        $('[data-toggle=colorpicker]', item).each(function (i, el) {
            var e = $(el),
                i = e.find(':input'),
                v = i.val()
                ;

            var cp = e
                .colorpicker({ 'format': null, 'useAlpha': true, 'autoInputFallback': false, 'autoHexInputFallback': false })
                .on('change', function (ev, t) {
                    if (!ev || !ev.color) return;

                    ev.color.format = ev.color.alpha < 1 ? 'rgb' : 'hex';

                    $(this).find(':input').val(ev.color.string());
                })
                ;

            i.val(v);
        });

        $('[data-toggle=slider]', item).each(function (i, el) {
            var e = $(el),
                isPercentage = e.attr('data-slider-formatter-percentage') == 'true',
                uuid = 'slider-' + uuidv4()
                ;

            e.bootstrapSlider({
                id: uuid,
                formatter: function (v) {
                    return isPercentage ? v + '%' : v;    
                }
            });
        });

        var l = root.find('.nav-tabs').find('[data-toggle-language].active').attr('data-toggle-language');

        item.find('[data-language]').addClass('d-none');
        item.find('[data-language=' + l + ']').removeClass('d-none');

        item.on('change', '[data-name=type]', function () {
            var e = $(this),
                t = e.val(),
                p = e.closest('.card');

            p.find('[data-if-type]').hide();
            p.find('[data-if-type*=' + t + ']').show();
        });
        item.find('[data-name=type]').trigger('change');

        item.on('change', '[data-kind=query-list]', function () {
            var html = $(this).closest('.card').find('[data-toggle=html]')
                ;

            setupTags(html);
        });
        item.find('[data-kind=query-list]').trigger('change');

        right.trigger('project.refresh-subtitles');
    },
    serializeItem = function (item) {
        var result = {
            '$type': item.attr('data-type'),
            id: item.attr('data-id'),
        };

        $('> .card-body > .form [data-name]', item).each(function () {
            var e = $(this),
                val = e.is(':checkbox') ? e.is(':checked') : e.val()
                ;

            if (e.attr('data-value-type') === 'decimal')
                val = parseFloat((val || '').replace(',', '.'));

            setDeep(result, e.attr('data-name'), val);
        });

        var items = $('> .card-body > [data-accepts] > .card', item);

        if (items.length) {
            result.items = [];

            items.each(function () {
                var i = serializeItem($(this));

                if (i) result.items.push(i);
            });
        }

        return result;
    },
    serializeContent = function () {
        var content = {
            '$type': right.attr('data-type'),
            items: []
        }

        $(' > .card', right).each(function () {
            var item = serializeItem($(this));

            if (item) content.items.push(item);
        });

        return content;
    },
    distinctArrayBy = function (arr, propName) {
        var result = arr.reduce(function (arr1, e1) {
            var matches = arr1.filter(function (e2) {
                return e1[propName] == e2[propName];
            })
            if (matches.length == 0)
                arr1.push(e1)
            return arr1;
        }, []);

        return result;
    }, 
    setupTags = function (el) {
        var selects = el.closest('.card').find('select[data-kind=query-list] option:selected'),
            tags = []
            ;

        if (selects.length) {
            selects.each(function () {
                var x = ($(this).data() || {}).tags;

                if (x && x.length)
                    $.each(x, function (i1, e1) {
                        if (e1)
                            tags.push(e1);
                    });
            });
        }

        var result = distinctArrayBy(tags, 'name') || [];

        if (result && result.length)
            el.data('tags', result);
        else
            el.removeData('tags');

        el.trigger('RefreshHtmlTags');
    };

    right.on('project.added', function (ev, el, target) {
        var item = $(el);

        item.attr('data-id', uuidv4());

        initForm(item);
    });

    $('form', root)
        .submit(function (ev) {
            var content = serializeContent();

            $('[data-toggle=json-target]', root).val(JSON.stringify(content));
        })
        .on('project.refreshed', function () {
            root.find('[data-toggle=html]').each(function () {
                setupTags($(this));
            });
        });

    initForm(right);

    (function () {
        root.on('click', '[data-toggle-language]', function () {
            var e = $(this),
                l = e.attr('data-toggle-language'),
                c = e.closest('.form-group');

            if (!c.length) c = e.closest('.card');

            // Tabs
            e.closest('.nav-tabs').find('.nav-link').removeClass('active');
            e.addClass('active');

            // Data
            c.find('[data-language]').addClass('d-none');
            c.find('[data-language=' + l + ']').removeClass('d-none');

            right.trigger('project.refresh-subtitles');
        });
    })();

    (function (right) {
        var reindex = function (root) {
            root.find('[data-toggle=template-item]').each(function (i, e) {
                $(e).find('[name]').each(function (ii, ee) {
                    var input = $(ee),
                        name = input.attr('name').replace(/\[\d*\]/g, '[' + i + ']'),
                        dataName = input.attr('data-name').replace(/\[\d*\]/g, '[' + i + ']')
                        ;

                    input
                        .attr('name', name)
                        .attr('data-name', dataName);
                });
            });
        };

        right.on('click', '[data-toggle=template-add]', function () {
            var e = $(this),
                root = e.closest('.card').find('[data-toggle=template-list]'),
                copy = $($(e.attr('data-target')).html())
                ;

            root.append(copy);

            reindex(root);

            initForm(copy);
        });

        right.on('click', '[data-toggle=template-remove]', function () {
            var e = $(this),
                item = e.closest('[data-toggle=template-item]'),
                root = e.closest('.card').find('[data-toggle=template-list]');

            item.remove();

            reindex(root);
        });
    })(right);

    (function () {
        var refreshTimeout = null,
            refresh = function () {
                $('[data-toggle=card-subtitle]', right).each(function () {
                    var e = $(this),
                        c = e.closest('.card'),
                        s = c.find('> .card-body > .form > [data-source=card-subtitle] :input:not(.d-none)'),
                        v = s.val()
                        ;

                    e.html(v ? ('&nbsp;&bull;&nbsp;' + v) : '');
                });
            };

        right.on('project.refresh-subtitles', function () {
            if (refreshTimeout) clearTimeout(refreshTimeout);

            refreshTimeout = setTimeout(refresh, 100);
        });

        right.on('input keydown', '[data-source=card-subtitle] :input', function () {
            right.trigger('project.refresh-subtitles');
        });

        right.trigger('project.refresh-subtitles');
    })();
});