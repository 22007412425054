$(function () {
    var options = {
        singular: 'Choose file',
        plural: 'Choose files'
    };

    var setLabel = function (label, input, override) {
        label.html(override || (input.attr('multiple') == 'multiple' ? options.plural : options.singular));
    }; 

    $('.custom-file-label').each(function () {
        var label = $(this),
            input = label.closest('.custom-file').find('.custom-file-input')
            ;

        setLabel(label, input);
    });

    $('.custom-file-input').on('change', function (ev) {
        var input = $(this),
            label = input.closest('.custom-file').find('.custom-file-label')
            ;

        if (!label.length) return;

        var array = [];

        for (var i = 0; i < ev.target.files.length; i++) {
            array.push(ev.target.files[i].name);
        }

        setLabel(label, input, array.join(', '));
    });
});