jQuery.fn.extend({
    insertAtCaret: function (myValue) {
        return this.each(function (i) {
            if (document.selection) {
                this.focus();
                sel = document.selection.createRange();
                sel.text = myValue;
                this.focus();
            }
            else if (this.selectionStart || this.selectionStart == '0') {
                var startPos = this.selectionStart;
                var endPos = this.selectionEnd;
                var scrollTop = this.scrollTop;
                this.value = this.value.substring(0, startPos) + myValue + this.value.substring(endPos, this.value.length);
                this.focus();
                this.selectionStart = startPos + myValue.length;
                this.selectionEnd = startPos + myValue.length;
                this.scrollTop = scrollTop;
            } else {
                this.value += myValue;
                this.focus();
            }
        })
    }
});

function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

/**
 * Dynamically sets a deeply nested value in an object.
 * Optionally "bores" a path to it if its undefined.
 * @function
 * @param {!object} obj  - The object which contains the value you want to change/set.
 * @param {!array} path  - The array representation of path to the value you want to change/set.
 * @param {!mixed} value - The value you want to set it to.
 */
function setDeep(obj, path, value) {
    var context = obj;  // a moving reference to internal objects within obj
    var parts = path.split('.');
    var len = parts.length;
    var re = /([a-zA-Z]+)(\[(\d)\])+/; // matches: item[0]

    for (var i = 0; i < len - 1; i++) {
        var elem = parts[i];
        var match = re.exec(elem);

        if (match !== null) {
            // item[0]
            if (!context[match[1]])
                context[match[1]] = [];

            context = context[match[1]];

            if (!context[match[3]])
                context[match[3]] = {};

            context = context[match[3]];

        } else {
            // item
            if (!context[elem])
                context[elem] = {};

            context = context[elem];
        }
    }

    var elem = parts[len - 1];

    context[elem] = value;
}